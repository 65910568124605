<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div id="Tablemain" class="main-con" ref="MaturityAssessment">
      <div class="mat-info-con">
        <div class="plant-dd">
          <label for="pillar" class="form-label select-lable lable-text">Plant</label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar"
            :value="plantSelectedInAsmtHome" @change="pillarChanged($event)">
            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
              {{ plant.plantName }}, {{ plant.regionCode }}
            </option>
          </select>
        </div>
        <div class="plant-card" :class="{ 'cml-main-con': plantDetails.currentMaturityLevel > 2 }"
          v-if="pillarDetails.length > 0">
          <div :class="{ 'cml-con': plantDetails.currentMaturityLevel > 2 }">
            <div>
              <div class="info-card-lable">Current Maturity Level</div>
              <div class="info-card-value">
                {{ plantDetails.currentMaturityLevel }}
              </div>
            </div>
            <div class="crt-btn-con" v-if="plantDetails.currentMaturityLevel > 2">
              <b-button class="primar-btn button-sm certify-btn-plt" pill variant="outline-primary"
                :disabled="(plantDetails.validationStatus == 'True' ? true : plantDetails.certifyStatus == 'True' ? false : true) || !checkPersonaForCertify"
                @click="certifyPlant(plantDetails.currentMaturityLevel)">
                Certify
              </b-button>
            </div>
          </div>
        </div>
        <div class="plant-card" v-if="pillarDetails.length > 0">
          <div class="info-card-lable">Current Maturity Score</div>
          <div class="info-card-value">
            {{ plantDetails.currentMaturityScore }}
          </div>
        </div>
        <div class="plant-card" v-if="pillarDetails.length > 0">
          <div class="info-card-lable">Total Completed Practices</div>
          <div class="info-card-value">
            {{ plantDetails.plantCompletedPractices }}
          </div>
        </div>
        <div class="plant-card2" v-if="pillarDetails.length > 0">
          <div class="info-card-lable">Total Not Sustained Practices</div>
          <div class="info-card-value">
            {{ plantDetails.plantNotSustainedPractices }}
          </div>
        </div>
        <div id="ExportPlantAssessment" style="margin-left: auto">
          <div>
            <b-button class="asmt-bt button-sm" pill variant="outline-primary"
              @click="exportToPDFMaturityAssessmentTable()"> Export Plant Assessment to PDF </b-button>
          </div>
        </div>
      </div>
      <div class="level-con" v-if="pillarDetails.length > 0 && plantDetails.currentMaturityLevel > 2">
        <div class="row level-row justify-content-center align-items-center">
          <div class="col">
            <div class="level-status target-card-lable">
              Level {{ plantDetails.currentMaturityLevel }} Completion Date
            </div>
            <div class="level-date target-card-value">
              {{ plantDetails.currentCompletionDate | dateFormater }}
            </div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              Level {{ plantDetails.currentMaturityLevel }} Validation Date
            </div>
            <div class="level-date target-card-value">
              {{ plantDetails.currentValidationDate | dateFormater }}
            </div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              Level {{ plantDetails.currentMaturityLevel }} Sustainment Date
            </div>
            <div class="level-date target-card-value">
              {{ plantDetails.currentSustainmentDate | dateFormater }}
            </div>
          </div>
        </div>
      </div>
      <div class="mat-stats-con">
        <div class="ma-status">Status :</div>
        <div class="mat-status-con"><img alt="ontrack" src="../../../assets/blue-tick.svg" /><span
            class="ma-status-able mr-lf-3">On Track</span></div>

        <div class="mat-status-con"><img alt="offtrack" src="../../../assets/maturity-not-cmpl.svg" /><span
            class="ma-status-able mr-lf-3">Off Track</span></div>
      </div>
      <div class="asmt-tbl">
        <b-table id="asmt-table" class="action-table gn-table-gn" responsive :items="pillarDetails" :fields="fields"
          :tbody-tr-class="rowClass">
          <template #cell(actions)="row">
            <div class="">
              <b-button :disabled="row.item.pillarActiveStatus == 'N'" class="asmt-bt take-ast-btn button-sm" pill
                variant="outline-primary" @click="(pillarId = row.item.pillarId), takeAssement(row.item, $event.target)">
                Assessment
              </b-button>
              <b-button class="asmt-bt button-sm" pill variant="outline-primary"
                :disabled="row.item.pillarActiveStatus == 'N'" @click="(pillarId = row.item.pillarId), viewAssement()">
                View Results
              </b-button>
              <button
                :class="row.item.pillarActiveStatus == 'N' ? 'download-view action-btn-disabled' : 'download-view action-btn'"
                style="background: transparent; margin-right: 0px; margin-left: 1rem" type="button"
                :disabled="row.item.pillarActiveStatus == 'N'" v-b-tooltip.hover title="Export to Excel"
                @click="((pillarId = row.item.pillarId), (pillarCode = row.item.pillarCode), (pillarName = row.item.pillarName)), exportToExcel()">
                <img alt="icon"
                  :src="row.item.pillarActiveStatus == 'N' ? require(`../../../assets/download_excelicon_disabled.svg`) : require(`../../../assets/download_excelicon.svg`)" />
              </button>
              <button
                :class="row.item.pillarActiveStatus == 'N' ? 'download-view action-btn-disabled' : 'download-view action-btn'"
                style="background: transparent; margin-right: 0px; margin-left: 1rem" type="button"
                :disabled="row.item.pillarActiveStatus == 'N'" v-b-tooltip.hover title="Export to PDF"
                @click="((pillarId = row.item.pillarId), (pillarCode = row.item.pillarCode), (pillarName = row.item.pillarName)), exportToPDF()">
                <img alt="icon"
                  :src="row.item.pillarActiveStatus == 'N' ? require(`../../../assets/download_pdficon_disabled.svg`) : require(`../../../assets/download_pdficon.svg`)" />
              </button>
            </div>
            <div id="lastUpdatedLink" class="last-ast-date lat-asmt-date">
              <div v-if="row.item.lastAssesmentDate">
                Last Updated :
                <span>{{ row.item.lastAssesmentDate | dateFormater }} </span>
                <br />
                <span id="checkActionLink" style="padding-left: 10px; padding-right: 10px" @click="
                  checkAction(
                    row.item.pillarId,
                    row.item.pillarName,
                    row.item.practiceId,
                    row.item.lastActionDone,
                    row.item.practiceName,
                    row.item.practiceNumber,
                    row.item.principleId,
                    row.item.levelId
                  )
                ">{{ row.item.lastActionDone }}</span>
              </div>

              <span v-else>Last Updated: NA</span>
            </div>
          </template>
          <template #cell(overallCompletedActionToNextLevel)="row">
            {{ row.item.overallCompletedActionToNextLevel }}
            /
            {{ row.item.overallActionToNextLevel }}
          </template>
          <template #cell(currentMaturityLevel)="row">
            {{ row.item.currentMaturityLevel }}
            <b-button v-if="row.item.currentMaturityLevel > 2 && plantDetails.currentMaturityLevel > 2"
              class="asmt-bt button-sm certify-btn" pill variant="outline-primary"
              :disabled="(row.item.validationStatus == 'True' ? true : row.item.certifyStatus == 'True' ? false : true) || !checkPersonaForCertify"
              @click="certifyByPillar(row.item.pillarId, row.item.currentMaturityLevel)">
              Certify
            </b-button>
          </template>
          <template #cell(pillarCode)="row">
            <div>
              <div class="d-flex">
                <div v-if="row.item.pillarActiveStatus == 'Y' && row.item.maturityAssesmentStatus == 'ONTRACK'">
                  <img alt="ontrack" src="../../../assets/blue-tick.svg" />
                </div>
                <div v-if="row.item.pillarActiveStatus == 'Y' && row.item.maturityAssesmentStatus == 'OFFTRACK'">
                  <img alt="not Completed" src="../../../assets/maturity-not-cmpl.svg" />
                </div>
                <div class="pl-code" :id="row.item.pillarCode">
                  <span class="mr-lf-3">{{ row.item.pillarCode }}</span>
                </div>
                <b-tooltip :target="row.item.pillarCode" title="Tooltip title">
                  {{ row.item.pillarName }}
                </b-tooltip>
              </div>
            </div>
          </template>
          <template #top-row v-if="pillarDetails.length === 0">
            <td class="text-center" :colspan="fields.length">No Data Found</td>
          </template>
        </b-table>
      </div>
    </div>
    <div>
      <b-modal centered size="md" id="bv-export-modal" modal-class="export-modal" no-close-on-backdrop no-close-on-esc
        hide-header-close hide-header hide-footer modal-center>
        <div class="d-block text-center">
          <span style="font-size: 1.75rem; text-align: center; color: #274ba7"><b-spinner label="Loading..." />
            {{ exportModalMessage }}
          </span>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import Role from "../../../common/constants/roles";
import Helper from "../../../common/helper";
import XLSX from "xlsx";
import XLSX2 from "xlsx-js-style";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";

export default {
  name: "Assessment",
  data() {
    return {
      roles: Role,
      showLoader: true,
      plantList: [],
      plantDetails: {},
      plantId: {},
      apiCount: 0,
      pillarName: "",
      pillarId: "",
      pillarCode: "",
      fields: [
        {
          key: "pillarCode",
          label: "Pillar",
          sortable: false
        },
        {
          key: "currentMaturityLevel",
          label: "Current Maturity Level",
          sortable: false
        },
        {
          key: "currentMaturityScore",
          label: "Current Maturity Score",
          sortable: false
        },
        {
          key: "inprogressPractices",
          label: "Total Inprogress Practices",
          sortable: false
        },
        {
          key: "totalAllActionCompleted",
          label: "Total Completed Practices",
          sortable: false
        },
        {
          key: "totalNotSustained",
          label: "Total Not Sustained Practices",
          sortable: false
        },
        // {
        //   key: "targetMaturityScore",
        //   label: "Target Maturity Score",
        //   sortable: false,
        // },
        {
          key: "overallTargetActions",
          label: Helper.currentYear() + " Overall Target Practices ",
          sortable: false
        },
        {
          key: "totalActionCommited",
          label: Helper.currentYear() + " Practice Committed",
          sortable: false
        },
        {
          key: "completedActionsWithoutCommitedDate",
          label: Helper.currentYear() + " Practice Completed without Committed Date",
          sortable: false
        },
        {
          key: "totalActionCompleted",
          label: Helper.currentYear() + " Practice Completed",
          sortable: false
        },
        {
          key: "overallCompletedActionToNextLevel",
          label: "Overall Practices Completed To Next Level",
          sortable: false
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      items: [
        {
          isActive: true,
          pillar: "PEC",
          status: "completed",
          currentMaturityLevel: "3",
          currentMaturityScore: "2.5",
          targetMaturityScore: "3.1",
          actionCommitted: "4",
          actionCompleted: "10",
          overallActionsCompletedToNextLevel: "200/399"
        },
        {
          isActive: true,
          pillar: "OGW",
          status: "completed",
          currentMaturityLevel: "2",
          currentMaturityScore: "2.3",
          targetMaturityScore: "3.1",
          actionCommitted: "4",
          actionCompleted: "10",
          overallActionsCompletedToNextLevel: "200/399"
        },
        {
          isActive: true,
          pillar: "ZLC",
          status: "review",
          currentMaturityLevel: "2",
          currentMaturityScore: "2.3",
          targetMaturityScore: "3.1",
          actionCommitted: "4",
          actionCompleted: "10",
          overallActionsCompletedToNextLevel: "200/399"
        },
        {
          isActive: true,
          pillar: "WPO",
          status: "not_completed",
          currentMaturityLevel: "2",
          currentMaturityScore: "2.3",
          targetMaturityScore: "3.1",
          actionCommitted: "4",
          actionCompleted: "10",
          overallActionsCompletedToNextLevel: "200/399"
        },
        {
          isActive: true,
          pillar: "DEC",
          status: "completed",
          currentMaturityLevel: "2",
          currentMaturityScore: "2.3",
          targetMaturityScore: "3.1",
          actionCommitted: "4",
          actionCompleted: "10",
          overallActionsCompletedToNextLevel: "200/399"
        },
        {
          isActive: true,
          pillar: "CSD",
          status: "completed",
          currentMaturityLevel: "2",
          currentMaturityScore: "2.3",
          targetMaturityScore: "3.1",
          actionCommitted: "4",
          actionCompleted: "10",
          overallActionsCompletedToNextLevel: "200/399"
        }
      ],
      pillarDetails: [],
      assessmentDetailsForDownload: [],
      exportModalMessage: "Exporting Assessment..."
    };
  },
  components: {
    Breadcrumb,
    Loader
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 1
    });
    this.$store.dispatch("breadcrumb", {
      title: "Maturity Model - Assessment",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Maturity Model Assessment", primary: false }
      ]
    });
  },
  created() {
    Helper.setAssessmentStatus("assessment");
    this.getPlantList();
  },
  computed: {
    ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"]),
    checkPersonaForCertify() {
      if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) return true;
      else return false;
    }
  },
  methods: {
    rowClass(item) {
      if (item && item.pillarActiveStatus == "N") return "inactive-row";
    },
    takeAssement(data, event) {
      this.$router.push(
        "take-assessment/" +
        this.plantId.plantId +
        "/" +
        this.pillarId +
        "/" +
        data.pillarName +
        "/assessment" +
        "/" +
        this.plantId.plantName +
        ", " +
        this.plantId.regionCode +
        "/0/1"
      );
      console.log(event);
    },
    viewAssement() {
      this.$router.push("/view-assessment/" + this.plantId.plantId + "/" + this.pillarId + "/" + this.plantId.plantName + ", " + this.plantId.regionCode);
    },
    checkAction(pillarId, pillarName, practiceId, lastActionDone, practiceName, practiceNumber, principleId, levelId) {
      console.log("PlantID: " + this.plantId.plantId + " | PlantName: " + this.plantId.plantName);
      console.log("Pillar Id: " + pillarId + " | Pillar Name: " + pillarName);
      console.log("Practice Id: " + practiceId + " | Practice Name: " + practiceName + " | Practice Number: " + practiceNumber + " | Last Update Done: " + lastActionDone);
      console.log("Principle Id: " + principleId + " | Level Id: " + levelId);
      let isLinkToUpdate = 1;
      let principle = "&" + principleId + "," + isLinkToUpdate;
      this.$router.push(
        "take-assessment/" +
        this.plantId.plantId +
        "/" +
        pillarId +
        "/" +
        pillarName +
        "/assessment" +
        "/" +
        this.plantId.plantName +
        ", " +
        this.plantId.regionCode +
        // + "/0/1"   // levelId / principleId
        "/" +
        levelId +
        "/" +
        principle
        // + "/" +
        // isLinkToUpdate
        // + '/' +
        //  lastmodifiedpracticeId
        // + '/' +
        // lastmodifiedpracticeNumber

        // + '/' +
        // lastmodifiedpracticeName
      );
    },
    getPlantList() {
      console.log("*****");

      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        this.plantList = data.data;
        let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
        let index = 0;
        if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
        else if (this.loggedInUserData.plantId) {
          let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
          if (plantIndex > -1) {
            index = plantIndex;
            plantId = this.plantList[index].plantId;
          }
        } else if (this.loggedInUserData.regionName) {
          let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
          if (plantIndex > -1) {
            index = plantIndex;
            plantId = this.plantList[index].plantId;
          }
        }
        this.plantId = this.plantList[index];
        this.$store.dispatch("plantSelectedInAsmtHome", plantId);
        this.getPlantDetails();
        this.getPlantPillarDetails();
      });
    },
    pillarChanged(event) {
      this.showLoader = true;
      this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
      this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
      this.getPlantDetails();
      this.getPlantPillarDetails();
    },
    getPlantDetails() {
      let url = Api.PLANTDETAILS + this.plantId.plantId;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.apiCount++;
        this.plantDetails = data.data;
        if (this.apiCount == 2) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
    getPlantPillarDetails() {
      ApiCalls.httpCallDetails(Api.PLANTPILLARDETAILS + this.plantId.plantId, "get").then((data) => {
        this.apiCount++;
        this.pillarDetails = data.data;
        if (this.apiCount == 2) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
    certifyPlant(currentMaturityLevel) {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.CERTIFYPLANT + this.plantId.plantId + "/" + currentMaturityLevel, "post", {}).then((data) => {
        this.getPlantList();
      });
    },
    certifyByPillar(pillarId, currentMaturityLevel) {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.CERTIFYPILLAR + pillarId + "/" + this.plantId.plantId + "/" + currentMaturityLevel, "post", {}).then((data) => {
        this.getPlantList();
      });
    },
    exportToExcel() {
      // On Click Excel download button
      console.log("Excel download: Pillar[" + this.pillarId + "-" + this.pillarName + " (" + this.pillarCode + ")" + "] || Plant[" + this.plantId.plantId + "]");
      this.downloadAssementToExcelFinal();
    },
    exportToPDF() {
      // On Click PDF download button
      console.log("PDF download: Pillar[" + this.pillarId + "-" + this.pillarName + " (" + this.pillarCode + ")" + "] || Plant[" + this.plantId.plantId + "]");
      this.downloadAssementToPDFFinal();
    },
    exportToPDFMaturityAssessmentTable() {
      // On Click Excel download button
      //console.log('PDF download table: Plant[' + this.plantId.plantId + ']');
      this.downloadAssementTableToPDFFinal();
    },
    downloadAssementToExcelFinal() {
      let thispillarId = this.pillarId;
      console.log("Loading Start", thispillarId);
      this.exportModalMessage = ` Exporting ${this.pillarCode} Assessment Report.xlsx`;
      this.$bvModal.show("bv-export-modal");

      ApiCalls.httpCallDetails(Api.GETPLANTPILLARASSESSMENTFORDOWNLOAD + this.plantId.plantId + "/" + this.pillarId, "get", {}).then((data) => {
        this.assessmentDetailsForDownload = data.data;

        let wb = XLSX2.utils.book_new(); // create Workbook of Excel
        let sheetName = this.pillarCode + " Assessment Report"; // define worksheet name

        //1st row - TITLE (should be merged)
        // Here s = start,  e= end | r = row, c=col,
        // r: 0 = row 1, c: 1 = column B ==> B1 (start cell range)
        // r: 0 = row 1, c: 1 = column G ==> G1 (end cell range)
        const StatusColorCodes = {
          12: "00B050", //NotStarted  -
          13: "00B050", //In Progress -
          14: "00B050", //Completed   -
          15: "", //Not Sustained -
          16: "", //Approved
          17: "", //Not Approved
          18: "00B050", //On Track test
          19: "", //Off Track
          20: "", //Pending Validation
          26: "FF7C80", //Completed (Major Change)  -
          27: "FF7C80", //In Progress (Major Change)
          28: "FF7C80", //Not Started (Major Change)  -
          29: "FF7C80", //Not Sustained (Major Change)
          30: "FF7C80", //On Track (Major Change)
          31: "FF7C80", //Off Track (Major Change)
          32: "FF0000" //Deleted                 -
        };
        console.log(StatusColorCodes);
        const mergeRow1 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }];
        let row1colsWidth = [
          { width: 17 }, // first column
          { width: 25 }, // second column
          { width: 25 }, //...
          { width: 25 },
          { width: 25 },
          { width: 25 },
          { width: 25 }
        ];

        let row1 = [
          {
            v: this.pillarCode + " Pillar Maturity Levels",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 16,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "",
            t: "s",
            s: {
              //fill: { fgColor : {rgb: "C0C0C0"}, patternType : 'solid' },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "",
            t: "s",
            s: {
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "",
            t: "s",
            s: {
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "",
            t: "s",
            s: {
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "",
            t: "s",
            s: {
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "",
            t: "s",
            s: {
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          }
        ];
        let ws = XLSX2.utils.aoa_to_sheet([row1]);
        ws = XLSX2.utils.sheet_add_aoa(ws, [row1], { origin: "A1" }); //Add text to Cell A1
        ws["!merges"] = mergeRow1; //merge cells => [!merges] is a predefined keyword to merge cell
        ws["!cols"] = row1colsWidth;
        //2nd row
        let row2 = [
          {
            v: "Principle",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "555555"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "0",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "1",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "2",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "3",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "4",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          },
          {
            v: "5",
            t: "s",
            s: {
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
              },
              //fill: { fgColor : {rgb: "000080"}, patternType : 'solid' },
              alignment: { vertical: "center", horizontal: "center" },
              border: {
                top: { style: "thick", color: { rgb: "000000" } },
                bottom: { style: "thick", color: { rgb: "000000" } },
                left: { style: "thick", color: { rgb: "000000" } },
                right: { style: "thick", color: { rgb: "000000" } }
              }
            }
          }
        ];
        ws = XLSX2.utils.sheet_add_aoa(ws, [row2], { origin: "A2" }); //Add text to Cell A2

        //3rd row - this should loop to Principles count
        // start row = A3 up to count of Principles
        let startCellForPrincipleNames = 3;
        let dataCellRangeForPractices = ["B", "C", "D", "E", "F", "G"];
        let startCellForPractices = 3;
        this.assessmentDetailsForDownload.forEach((item) => {
          let rowPrinciple = [
            {
              v: item.principleName,
              t: "s",
              s: {
                font: {
                  name: "Calibri",
                  sz: 9,
                  bold: true,
                  color: { rgb: "000000" }
                },
                alignment: {
                  wrapText: true,
                  vertical: "center",
                  horizontal: "center"
                },
                //fill: { fgColor : {rgb: item.assessmentDetails.practiceList[0].statusId !==32? "808080" : StatusColorCodes[item.assessmentDetails.practiceList[0].statusId]}, patternType : 'solid' },
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            }
          ];

          let rowGuidelines = [
            {
              v: "Guidelines",
              t: "s",
              s: {
                font: {
                  name: "Calibri",
                  sz: 9,
                  bold: true,
                  color: { rgb: "000000" }
                },
                alignment: {
                  wrapText: true,
                  vertical: "center",
                  horizontal: "center"
                },
                //fill: { fgColor : {rgb: "C0C0C0"}, patternType : 'solid' },
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            },
            {
              v: "",
              t: "s",
              s: {
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            },
            {
              v: "",
              t: "s",
              s: {
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            },
            {
              v: "",
              t: "s",
              s: {
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            },
            {
              v: "",
              t: "s",
              s: {
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            },
            {
              v: "",
              t: "s",
              s: {
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            },
            {
              v: "",
              t: "s",
              s: {
                border: {
                  top: { style: "thick", color: { rgb: "000000" } },
                  bottom: { style: "thick", color: { rgb: "000000" } },
                  left: { style: "thick", color: { rgb: "000000" } },
                  right: { style: "thick", color: { rgb: "000000" } }
                }
              }
            }
          ];

          ws = XLSX2.utils.sheet_add_aoa(ws, [rowPrinciple], {
            origin: "A" + startCellForPrincipleNames
          });
          startCellForPrincipleNames = startCellForPrincipleNames + 1;
          ws = XLSX2.utils.sheet_add_aoa(ws, [rowGuidelines], {
            origin: "A" + startCellForPrincipleNames
          });

          if (item.assessmentDetails.practiceList.length != 0) {
            item.assessmentDetails.practiceList.forEach((practiceitem, index) => {
              let rowPractices = [
                {
                  v: practiceitem.practiceDescription,
                  t: "s",
                  s: {
                    font: {
                      name: "Calibri",
                      sz: 8,
                      //color: { rgb: practiceitem.statusId !==32? "000000" :  "FFFFFF"},},
                      color: { rgb: "000000" }
                    },
                    alignment: {
                      wrapText: true,
                      vertical: "center",
                      horizontal: "center"
                    },
                    border: {
                      top: { style: "thick", color: { rgb: "000000" } },
                      bottom: { style: "thick", color: { rgb: "000000" } },
                      left: { style: "thick", color: { rgb: "000000" } },
                      right: { style: "thick", color: { rgb: "000000" } }
                    }
                    //fill: {  fgColor: { rgb: index !== 0? StatusColorCodes[practiceitem.statusId] :
                    //          practiceitem.statusId !==32? "FFFFFF": StatusColorCodes[practiceitem.statusId]}   }
                  }
                }
              ];
              ws = XLSX2.utils.sheet_add_aoa(ws, [rowPractices], {
                origin: dataCellRangeForPractices[index] + startCellForPractices
              });
            });
          }

          startCellForPrincipleNames = startCellForPrincipleNames + 1;
          startCellForPractices = startCellForPractices + 2; // as it needs to skip the Guidelines row
        });

        this.$bvModal.hide("bv-export-modal");

        XLSX2.utils.book_append_sheet(wb, ws, sheetName);
        XLSX2.writeFile(wb, sheetName + ".xlsx"); // name of the file is 'book.xlsx'
      }).catch(e => console.log(e));
    },
    downloadAssementTableToPDF() {
      alert("Export table to PDF started...");

      let ref = document.getElementById("Tablemain");
      console.log(ref);
      let clonedRef = document.getElementById("Tablemain").cloneNode(true);

      let refdestinationNew = document.getElementById("TablemainforPrint").appendChild(clonedRef);
      console.log("refdestinationNew");
      console.log(refdestinationNew);

      refdestinationNew.childNodes[0].childNodes[5].remove();

      refdestinationNew.childNodes[3].childNodes[0].childNodes[0].setAttribute("id", "clonedTable"); // set attribute id of cloned table
      let clonedtble = document.getElementById("clonedTable");
      let clonedtblerows = clonedtble.rows; // Getting the rows
      let rowLength = clonedtblerows.length;
      let rowIndexesToRemove = [];
      clonedtblerows[0].childNodes[9].innerHTML = "Comments";
      clonedtblerows[0].childNodes[9].style.textAlign = "center";

      for (let i = 0; i < rowLength; i++) {
        if (clonedtblerows[i].className == "inactive-row") {
          //remove 'inactive-row' (inactive pillars)
          // console.log('Row ' + i + ' | ' + 'className: ' + clonedtblerows[i].className + ' | ' + clonedtblerows[i].innerText);
          rowIndexesToRemove.push(i);
        } else {
          //just remove content of last cell/column with buttons
          if (i != 0) {
            clonedtblerows[i].childNodes[9].remove(); // remove buttons and replace with Comments header and blank cells
          }

          clonedtblerows[i].childNodes[4].remove(); // remove Total Not sustained practices column
          clonedtblerows[i].childNodes[3].remove(); // remove Total completed practices column
        }
      }

      rowIndexesToRemove.sort(function (a, b) {
        return b - a;
      }); //sort indexes descending for deletion
      for (const element of rowIndexesToRemove) {
        clonedtblerows[element].remove();
      }

      html2canvas(refdestinationNew, {
        logging: true,
        letterRendering: true,
        allowTaint: false,
        useCORS: true,
        scale: (1920 * 2) / window.innerWidth,
        backgroundColor: null
      })
        .then((canvas) => {
          let pdfName = "Test Assessment Report";
          let pdfMake = require("pdfmake/build/pdfmake.js");
          if (pdfMake.vfs == undefined) {
            let pdfFonts = require("pdfmake/build/vfs_fonts.js");
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
          }

          let img = new Image();
          img.src = canvas.toDataURL("image/jpg");

          let docDefinition = {
            pageSize: "A4",
            pageMargins: [10, 10, 10, 10],
            pageOrientation: "landscape",
            content: []
          };

          docDefinition.content.push({
            image: canvas.toDataURL("image/jpg"),
            width: 780,
            height: 400,
            positions: [
              {
                horizontalRatio: 0,
                left: 20,
                pageInnerHeight: 450.28,
                pageInnerWidth: 900.89,
                pageNumber: 1,
                pageOrientation: "landscape",
                top: 10,
                verticalRatio: 0.33312605489917385
              }
            ],
            x: 22,
            y: 240,
            alignment: "centre",
            //margin: [0, 140, 50, 0],
            margin: [10, 60, 10, 10]
          });

          pdfMake.createPdf(docDefinition).download(pdfName + ".pdf");
        })
        .catch((error) => {
          console.log("ERROR SAVING FESTIVAL IMAGE", error);
        });
    },
    downloadAssementTableToPDF2() {
      alert("Export table to PDF started...");
      let pdfName = "Test Assessment Report";
      let pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        let pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let pdfData = [];

      let docDefinition = {
        //content: "This is an sample PDF printed with pdfMake",
        //content: PlantTitle,
        content: pdfData,
        //content: PlantTitle + PillarTitle + pdfData,
        pageOrientation: "landscape",
        pageSize: "A4",
        pageMargins: [35, 35, 35, 35]
      };

      pdfMake.createPdf(docDefinition).download(pdfName + ".pdf");
    },
    downloadAssementTableToPDFFinal() {
      this.exportModalMessage = ` Exporting Plant Assessment Report (${this.plantId.plantName}).pdf`;
      this.$bvModal.show("bv-export-modal");
      let tblforPrint = document.createElement("div");
      tblforPrint.setAttribute("id", "TablemainforPrint");
      tblforPrint.setAttribute("class", "main-con");
      document.body.append(tblforPrint);

      let ref = document.getElementById("Tablemain");
      console.log("ref");
      console.log(ref);

      let clonedRef = document.getElementById("Tablemain").cloneNode(true);
      console.log("clonedRef");
      console.log(clonedRef);

      // append cloned div in div TablemainforPrint
      let refdestinationNew = document.getElementById("TablemainforPrint").appendChild(clonedRef);
      console.log("refdestinationNew");
      console.log(refdestinationNew);

      // replace the Plant dropdown with text Plant display only
      let plantNameContainer = document.getElementById("pillar");
      let plantNameValue = plantNameContainer.options[plantNameContainer.selectedIndex].text;
      console.log("plantNameValue");
      console.log(plantNameValue);

      let plantDiv = document.createElement("div");
      let plantLabel = document.createElement("span");
      plantLabel.textContent = "Plant: ";
      plantLabel.style.fontWeight = "bold";
      plantLabel.style.fontSize = "18px";
      plantLabel.style.color = "#234372";
      let plantValue = document.createElement("span");
      plantValue.style.fontSize = "18px";
      plantValue.textContent = plantNameValue;

      plantDiv.appendChild(plantLabel);
      plantDiv.appendChild(plantValue);

      console.log("refdestinationNew.childNodes[0].childNodes[0]");
      console.log(refdestinationNew.childNodes[0].childNodes[0]);
      console.log(refdestinationNew.childNodes[0].childNodes[0].childNodes);
      refdestinationNew.childNodes[0].childNodes[0].childNodes[1].remove();
      refdestinationNew.childNodes[0].childNodes[0].childNodes[0].remove();
      refdestinationNew.childNodes[0].childNodes[0].appendChild(plantDiv);

      // remove ExportPlantAssessment button
      // console.log(refdestinationNew.childNodes[0].childNodes);    // node where ExportPlantAssessment button is located
      // console.log(refdestinationNew.childNodes[0].childNodes[5]);
      // console.log(refdestinationNew.childNodes[0].childNodes);    // node where ExportPlantAssessment button is located
      //console.log('refdestinationNew.childNodes[3].childNodes');
      //console.log(refdestinationNew.childNodes[3].childNodes);    // node where table columns were located
      //console.log('refdestinationNew.childNodes[3].childNodes[0]');
      //console.log(refdestinationNew.childNodes[3].childNodes[0]);
      refdestinationNew.childNodes[0].childNodes[5].remove();
      console.log("refdestinationNew.childNodes[3].childNodes[0].childNodes[0]");
      console.log(refdestinationNew.childNodes[3].childNodes[0].childNodes[0]);

      let clonedtble = refdestinationNew.childNodes[3].childNodes[0].childNodes[0];
      let clonedtblerows = clonedtble.rows;
      let rowLength = clonedtblerows.length;
      clonedtblerows[0].childNodes[9].innerHTML = "Comments";
      clonedtblerows[0].childNodes[9].style.textAlign = "center";

      let rowIndexesToRemove = [];
      for (let i = 0; i < rowLength; i++) {
        if (clonedtblerows[i].className == "inactive-row") {
          //remove 'inactive-row' (inactive pillars)
          // console.log('Row ' + i + ' | ' + 'className: ' + clonedtblerows[i].className + ' | ' + clonedtblerows[i].innerText);
          rowIndexesToRemove.push(i);
        } else {
          //just remove content of last cell/column with buttons
          if (i != 0) {
            clonedtblerows[i].childNodes[9].childNodes[1].remove();
            clonedtblerows[i].childNodes[9].childNodes[0].remove();
          }

          clonedtblerows[i].childNodes[4].remove(); // remove Total Not sustained practices column
          clonedtblerows[i].childNodes[3].remove(); // remove Total completed practices column
        }
      }

      // Remove Inactive Rows identified
      // console.log('rowIndexesToRemove before');
      // console.log(rowIndexesToRemove);
      rowIndexesToRemove.sort(function (a, b) {
        return b - a;
      }); //sort indexes descending for deletion
      for (const element of rowIndexesToRemove) {
        clonedtblerows[element].remove();
      }

      html2canvas(refdestinationNew, {
        logging: true,
        letterRendering: true,
        allowTaint: false,
        useCORS: true,
        scale: (1920 * 2) / window.innerWidth,
        backgroundColor: null
      })
        .then((canvas) => {
          document.getElementById("TablemainforPrint").remove();
          // //document.getElementById("TablemainforPrint").removeChild(refdestinationNew);
          let pdfName = "Plant Assessment Report";
          let pdfMake = require("pdfmake/build/pdfmake.js");
          if (pdfMake.vfs == undefined) {
            let pdfFonts = require("pdfmake/build/vfs_fonts.js");
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
          }

          let img = new Image();
          img.src = canvas.toDataURL("image/jpg");

          let docDefinition = {
            pageSize: "A4",
            pageMargins: [10, 10, 10, 10],
            pageOrientation: "landscape",
            content: []
          };

          docDefinition.content.push({
            image: canvas.toDataURL("image/jpg"),
            width: 780,
            height: 400,
            positions: [
              {
                horizontalRatio: 0,
                left: 20,
                pageInnerHeight: 450.28,
                pageInnerWidth: 900.89,
                pageNumber: 1,
                pageOrientation: "landscape",
                top: 10,
                verticalRatio: 0.33312605489917385
              }
            ],
            x: 22,
            y: 240,
            alignment: "centre",
            //margin: [0, 140, 50, 0],
            margin: [10, 60, 10, 10]
          });

          pdfMake.createPdf(docDefinition).download(pdfName + "(" + plantNameValue + ")" + ".pdf");
          this.$bvModal.hide("bv-export-modal");
        })
        .catch((error) => {
          console.log("ERROR SAVING FESTIVAL IMAGE", error);
        });
    },
    downloadAssementToPDF() {
      alert("Export to PDF started...");

      ApiCalls.httpCallDetails(Api.GETPLANTPILLARASSESSMENTFORDOWNLOAD + this.plantId.plantId + "/" + this.pillarId, "get", {}).then((data) => {
        this.assessmentDetailsForDownload = data.data;

        //construct the array
        const assessmentDetailsForDownloadArray = [];

        this.assessmentDetailsForDownload.forEach((item) => {
          item.assessmentDetails.practiceList.forEach((practiceitem, index) => {
            let actionItems = practiceitem.actions.actionItemsList;
            if (actionItems.length == 0) {
              let initialArray = [];
              initialArray.principleName = item.principleName;
              initialArray.targetMaturityScore = item.assessmentDetails.principleTargetMaturityLevel;
              initialArray.practice = practiceitem.practiceDescription;
              initialArray.level = practiceitem.levelId;
              initialArray.practiceStatus = practiceitem.statusName;
              initialArray.practiceCommittedDate = practiceitem.actions.practiceCommittedDate ? practiceitem.actions.practiceCommittedDate : "NONE";
              initialArray.practiceCompletedDate = practiceitem.actions.practiceCompletionDate ? practiceitem.actions.practiceCompletionDate : "NONE";
              initialArray.actionNumber = "NONE";
              initialArray.actionItem = "NONE";
              initialArray.actionStatus = "NONE";
              initialArray.actionCommittedDate = "NONE";
              initialArray.actionCompletedDate = "NONE";
              assessmentDetailsForDownloadArray.push(initialArray);
            } else {
              practiceitem.actions.actionItemsList.forEach((actionitem, index) => {
                let initialArray = [];
                initialArray.principleName = item.principleName;
                initialArray.targetMaturityScore = item.assessmentDetails.principleTargetMaturityLevel;
                initialArray.practice = practiceitem.practiceDescription;
                initialArray.level = practiceitem.levelId;
                initialArray.practiceStatus = practiceitem.statusName;
                initialArray.practiceCommittedDate = practiceitem.actions.practiceCommittedDate ? practiceitem.actions.practiceCommittedDate : "NONE";
                initialArray.practiceCompletedDate = practiceitem.actions.practiceCompletionDate ? practiceitem.actions.practiceCompletionDate : "NONE";
                initialArray.actionNumber = item.principleNumber + "." + practiceitem.levelId + "." + actionitem.actionName;
                initialArray.actionItem = actionitem.actionDescription;
                initialArray.actionStatus = actionitem.status;
                initialArray.actionCommittedDate = actionitem.committedDate;
                initialArray.actionCompletedDate = actionitem.completionDate;
                assessmentDetailsForDownloadArray.push(initialArray);
              });
            }
          });
        });

        const headers = {
          headerList: {
            col_1: {
              text: "Principle Name",
              style: "tableHeader",
              rowSpan: 2,
              alignment: "center",
              margin: [0, 8, 0, 0]
            },
            col_2: {
              text: "Target Maturity Level",
              style: "tableHeader",
              rowSpan: 2,
              alignment: "center",
              margin: [0, 8, 0, 0]
            },
            col_3: {
              text: "Practice",
              style: "tableHeader",
              rowSpan: 2,
              alignment: "center",
              margin: [0, 8, 0, 0]
            },
            col_4: {
              text: "Level",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_5: {
              text: "Practice Status",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_6: {
              text: "Practice Committed Date",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_7: {
              text: "Practice Completion Date",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_8: {
              text: "Action #",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_9: {
              text: "Action Items",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_10: {
              text: "Action Status",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_11: {
              text: "Action Committed Date",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            },
            col_12: {
              text: "Action Completion Date",
              style: "tableHeader",
              colSpan: 2,
              alignment: "center"
            }
          }
        };

        const headers2 = [
          {
            text: "Principle Name",
            style: "tableHeader",
            rowSpan: 2,
            alignment: "center",
            margin: [0, 8, 0, 0]
          },
          {
            text: "Target Maturity Level",
            style: "tableHeader",
            rowSpan: 2,
            alignment: "center",
            margin: [0, 8, 0, 0]
          },
          {
            text: "Practice",
            style: "tableHeader",
            rowSpan: 2,
            alignment: "center",
            margin: [0, 8, 0, 0]
          },
          {
            text: "Level",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Practice Status",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Practice Committed Date",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Practice Completion Date",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Action #",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Action Items",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Action Status",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Action Committed Date",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          },
          {
            text: "Action Completion Date",
            style: "tableHeader",
            colSpan: 2,
            alignment: "center"
          }
        ];

        const sampleArray2 = ["Data1", "Data2", "Data3", "Data4", "Data5", "Data6", "Data7", "Data8", "Data9", "Data10", "Data11", "Data12"]; //values
        const sampleArray3 = [
          ["Data1", "Data2", "Data3", "Data4", "Data5", "Data6", "Data7", "Data8", "Data9", "Data10", "Data11", "Data12"],
          ["Data11", "Data12", "Data13", "Data14", "Data15", "Data16", "Data17", "Data18", "Data19", "Data20", "Data21", "Data22"],
          ["Data21", "Data22", "Data23", "Data24", "Data25", "Data26", "Data27", "Data28", "Data29", "Data30", "Data31", "Data32"]
        ]; //values

        const sampleArray = [
          {
            principleName: "Management Leadership Commitment",
            targetMaturityScore: "4",
            practice: "Not Established",
            level: "0",
            practiceStatus: "Completed",
            practiceCommittedDate: "2019-12-31T00:00:00",
            practiceCompletedDate: "2019-12-30T19:00:00",
            actionNumber: "NONE",
            actionItem: "NONE",
            actionStatus: "NONE",
            actionCommittedDate: "NONE",
            actionCompletedDate: "NONE"
          },
          {
            principleName: "Management Leadership Commitment",
            targetMaturityScore: "4",
            practice:
              "A) Policy is signed, posted and communicated to all associates and contractors.\n B) The facility/operation governance council/steering committee is fully functional. The facility/operation governance council/steering committee allows a process for effective decision making in order to prioritize and escalate EHS and PEC MS gap closure. Detailed Y Charts with action plans are in place to support facility goals. EHS indicators are cascaded into operational functions. ",
            level: "3",
            practiceStatus: "Completed",
            practiceCommittedDate: "2019-12-31T00:00:00",
            practiceCompletedDate: "2019-12-30T19:00:00",
            actionNumber: "1.3.a",
            actionItem: '"1.Define and deploy roles, responsibility and accountability at all levels  2.Develop and deploy PEC MS element owners"',
            actionStatus: "Completed",
            actionCommittedDate: "2020-01-31T00:00:00",
            actionCompletedDate: "2020-01-31T00:00:00"
          }
        ];
        console.log(sampleArray, sampleArray3, sampleArray2, headers2, headers);
        //call ref for pdfmake
        let pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          let pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        const pdfData = [
          {
            table: {
              headerRows: 1,
              widths: "*",
              body: [
                [
                  "Principle Name",
                  "Target Maturity Level",
                  "Practice",
                  "Level",
                  "Practice Status",
                  "Practice Committed Date",
                  "Practice Completion Date",
                  "Action #",
                  "Action Items",
                  "Action Status",
                  "Action Committed Date",
                  "Action Completion Date"
                ], //headers

                //...sampleArray.map(intervObj =>
                ...assessmentDetailsForDownloadArray.map((intervObj) =>
                  // ['Test1','Test1','Test1','Test1','Test1','Test1','Test1','Test1','Test1','Test1','Test1','Test1']
                  [
                    intervObj.principleName,
                    intervObj.targetMaturityScore,
                    intervObj.practice,
                    intervObj.level,
                    intervObj.practiceStatus,
                    intervObj.practiceCommittedDate,
                    intervObj.practiceCompletedDate,
                    intervObj.actionNumber,
                    intervObj.actionItem,
                    intervObj.actionStatus,
                    intervObj.actionCommittedDate,
                    intervObj.actionCompletedDate
                  ]
                )
              ]
            }
          }
        ];

        let docDefinition = {
          content: pdfData,
          pageOrientation: "landscape",
          pageSize: "A4",
          pageMargins: [35, 35, 35, 35]
        };

        pdfMake.createPdf(docDefinition).download("Maturity Assessment.pdf");
      });
    },
    downloadAssementToPDF2() {
      alert("Export to PDF started...");
      ApiCalls.httpCallDetails(Api.GETPLANTPILLARASSESSMENTFORDOWNLOAD + this.plantId.plantId + "/" + this.pillarId, "get", {}).then((data) => {
        this.assessmentDetailsForDownload = data.data;

        let pdfName = this.pillarCode + " Assessment Report";

        //construct the array
        const assessmentDetailsForDownloadArray = [];
        this.assessmentDetailsForDownload.forEach((item) => {
          let principleName = item.principleName;
          let initialArray = [];
          let secondaryArray = [];
          initialArray.principleName = principleName;
          let practiceByIndex = [];
          item.assessmentDetails.practiceList.forEach((practiceitem, index) => {
            if (index === 0) {
              initialArray.practice0 = practiceitem.practiceDescription;
              initialArray.practiceStatus0 = practiceitem.statusId;
            }
            if (index === 1) {
              initialArray.practice1 = practiceitem.practiceDescription;
              initialArray.practiceStatus1 = practiceitem.statusId;
            }
            if (index === 2) {
              initialArray.practice2 = practiceitem.practiceDescription;
              initialArray.practiceStatus2 = practiceitem.statusId;
            }
            if (index === 3) {
              initialArray.practice3 = practiceitem.practiceDescription;
              initialArray.practiceStatus3 = practiceitem.statusId;
            }
            if (index === 4) {
              initialArray.practice4 = practiceitem.practiceDescription;
              initialArray.practiceStatus4 = practiceitem.statusId;
            }
            if (index === 5) {
              initialArray.practice5 = practiceitem.practiceDescription;
              initialArray.practiceStatus5 = practiceitem.statusId;
            }
          });

          assessmentDetailsForDownloadArray.push(initialArray);

          //set Guidelines
          secondaryArray.principleName = "Guidelines";
          secondaryArray.practice0 = "";
          secondaryArray.practice1 = "";
          secondaryArray.practice2 = "";
          secondaryArray.practice3 = "";
          secondaryArray.practice4 = "";
          secondaryArray.practice5 = "";
          assessmentDetailsForDownloadArray.push(secondaryArray);
        });

        let pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          let pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        let StatusColorCodes = {
          12: "#00B050", //NotStarted  -
          13: "#00B050", //In Progress -
          14: "#00B050", //Completed   -
          15: "", //Not Sustained -
          16: "", //Approved
          17: "", //Not Approved
          18: "#00B050", //On Track
          19: "", //Off Track
          20: "", //Pending Validation
          26: "#FF7C80", //Completed (Major Change)  -
          27: "#FF7C80", //In Progress (Major Change)
          28: "#FF7C80", //Not Started (Major Change)  -
          29: "#FF7C80", //Not Sustained (Major Change)
          30: "#FF7C80", //On Track (Major Change)
          31: "#FF7C80", //Off Track (Major Change)
          32: "#FF0000" //Deleted                 -
        };

        const pdfData = [
          {
            table: {
              headerRows: 1,
              //widths: '*',
              //widths: [ '25',  '25',  '25', '25', '25', '25'],
              widths: ["*", "*", "*", "*", "*", "*", "*"],
              body: [
                [
                  // header 1
                  {
                    text: "",
                    fillColor: "#C0C0C0", // gray
                    //image: 'assets/background_education.png',
                    width: 10,
                    heigth: 10
                  },
                  {
                    text: this.pillarCode + " Pillar Maturity Levels",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    colSpan: 6,
                    alignment: "center",
                    fontSize: 16,
                    bold: true
                  },

                  {
                    text: "",
                    fillColor: "#000080" // navy blue
                  },
                  {
                    text: "",
                    fillColor: "#000080" // navy blue
                  },
                  {
                    text: "",
                    fillColor: "#000080" // navy blue
                  },
                  {
                    text: "",
                    fillColor: "#000080" // navy blue
                  },
                  {
                    text: "",
                    fillColor: "#000080" // navy blue
                  }
                ],
                //header 2
                [
                  {
                    text: "Principle",
                    fillColor: "#555555", // gray
                    color: "#FFFFFF", // white
                    alignment: "center"
                  },
                  {
                    text: "0",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    alignment: "center"
                  },
                  {
                    text: "1",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    alignment: "center"
                  },
                  {
                    text: "2",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    alignment: "center"
                  },
                  {
                    text: "3",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    alignment: "center"
                  },
                  {
                    text: "4",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    alignment: "center"
                  },
                  {
                    text: "5",
                    fillColor: "#000080", // navy blue
                    color: "#FFFFFF", // white
                    alignment: "center"
                  }
                ],
                ...assessmentDetailsForDownloadArray.map((intervObj) => [
                  {
                    text: intervObj.principleName,
                    alignment: "center",
                    fontSize: 9,
                    fillColor: intervObj.principleName === "Guidelines" ? "#C0C0C0" : intervObj.practiceStatus0 !== 32 ? "#808080" : StatusColorCodes[intervObj.practiceStatus0],
                    color: intervObj.principleName === "Guidelines" ? "#000000" : "#FFFFFF",
                    bold: true
                  },
                  {
                    text: intervObj.practice0,
                    alignment: "center",
                    fontSize: 9,
                    fillColor: intervObj.practiceStatus0 !== 32 ? "#FFFFFF" : StatusColorCodes[intervObj.practiceStatus0],
                    color: intervObj.practiceStatus0 !== 32 ? "#000000" : "#FFFFFF"
                  },
                  {
                    text: intervObj.practice1,
                    alignment: "center",
                    fontSize: 8,
                    fillColor: StatusColorCodes[intervObj.practiceStatus1],
                    color: intervObj.practiceStatus1 !== 32 ? "#000000" : "#FFFFFF"
                  },
                  {
                    text: intervObj.practice2,
                    alignment: "center",
                    fontSize: 8,
                    fillColor: StatusColorCodes[intervObj.practiceStatus2],
                    color: intervObj.practiceStatus2 !== 32 ? "#000000" : "#FFFFFF"
                  },
                  {
                    text: intervObj.practice3,
                    alignment: "center",
                    fontSize: 8,
                    fillColor: StatusColorCodes[intervObj.practiceStatus3],
                    color: intervObj.practiceStatus3 !== 32 ? "#000000" : "#FFFFFF"
                  },
                  {
                    text: intervObj.practice4,
                    alignment: "center",
                    fontSize: 8,
                    fillColor: StatusColorCodes[intervObj.practiceStatus4],
                    color: intervObj.practiceStatus4 !== 32 ? "#000000" : "#FFFFFF"
                  },
                  {
                    text: intervObj.practice5,
                    alignment: "center",
                    fontSize: 8,
                    fillColor: StatusColorCodes[intervObj.practiceStatus5],
                    color: intervObj.practiceStatus5 !== 32 ? "#000000" : "#FFFFFF"
                  }
                ])
              ]
            }
          }
        ];

        let docDefinition = {
          content: pdfData,
          pageOrientation: "landscape",
          pageSize: "A4",
          pageMargins: [35, 35, 35, 35]
        };

        pdfMake.createPdf(docDefinition).download(pdfName + ".pdf");
      });
    },
    downloadAssementToPDFFinal() {
      this.exportModalMessage = ` Exporting ${this.pillarCode} Assessment Report.pdf`;
      this.$bvModal.show("bv-export-modal");
      ApiCalls.httpCallDetails(Api.GETPLANTPILLARASSESSMENTFORDOWNLOAD + this.plantId.plantId + "/" + this.pillarId, "get", {}).then((data) => {
        this.assessmentDetailsForDownload = data.data;

        let pdfName = this.pillarCode + " Assessment Report";

        //construct the array
        const assessmentDetailsForDownloadArray = [];
        this.assessmentDetailsForDownload.forEach((item) => {
          let principleName = item.principleName;
          let initialArray = [];
          let secondaryArray = [];
          initialArray.principleName = principleName;
          let practiceByIndex = [];
          item.assessmentDetails.practiceList.forEach((practiceitem, index) => {
            if (index === 0) {
              initialArray.practice0 = practiceitem.practiceDescription;
              initialArray.practiceStatus0 = practiceitem.statusId;
            }
            if (index === 1) {
              initialArray.practice1 = practiceitem.practiceDescription;
              initialArray.practiceStatus1 = practiceitem.statusId;
            }
            if (index === 2) {
              initialArray.practice2 = practiceitem.practiceDescription;
              initialArray.practiceStatus2 = practiceitem.statusId;
            }
            if (index === 3) {
              initialArray.practice3 = practiceitem.practiceDescription;
              initialArray.practiceStatus3 = practiceitem.statusId;
            }
            if (index === 4) {
              initialArray.practice4 = practiceitem.practiceDescription;
              initialArray.practiceStatus4 = practiceitem.statusId;
            }
            if (index === 5) {
              initialArray.practice5 = practiceitem.practiceDescription;
              initialArray.practiceStatus5 = practiceitem.statusId;
            }
          });

          assessmentDetailsForDownloadArray.push(initialArray);

          //set Guidelines
          secondaryArray.principleName = "Guidelines";
          secondaryArray.practice0 = "";
          secondaryArray.practice1 = "";
          secondaryArray.practice2 = "";
          secondaryArray.practice3 = "";
          secondaryArray.practice4 = "";
          secondaryArray.practice5 = "";
          assessmentDetailsForDownloadArray.push(secondaryArray);
        });

        let pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          let pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        let StatusColorCodes = {
          12: "#00B050", //NotStarted  -
          13: "#00B050", //In Progress -
          14: "#00B050", //Completed   -
          15: "", //Not Sustained -
          16: "", //Approved
          17: "", //Not Approved
          18: "#00B050", //On Track
          19: "", //Off Track
          20: "", //Pending Validation
          26: "#FF7C80", //Completed (Major Change)  -
          27: "#FF7C80", //In Progress (Major Change)
          28: "#FF7C80", //Not Started (Major Change)  -
          29: "#FF7C80", //Not Sustained (Major Change)
          30: "#FF7C80", //On Track (Major Change)
          31: "#FF7C80", //Off Track (Major Change)
          32: "#FF0000" //Deleted                 -
        };
        console.log(StatusColorCodes);
        const pdfData = [
          {
            table: {
              headerRows: 1,
              //widths: '*',
              //widths: [ '25',  '25',  '25', '25', '25', '25'],
              widths: ["*", "*", "*", "*", "*", "*", "*"],
              body: [
                [
                  // header 1
                  {
                    text: this.pillarCode + " Pillar Maturity Levels",
                    //fillColor: '#000080',     // navy blue
                    color: "#000000", // white
                    colSpan: 7,
                    alignment: "center",
                    fontSize: 16,
                    bold: true
                  },
                  {
                    text: ""
                    //fillColor: '#C0C0C0',     // gray
                  },
                  {
                    text: ""
                    //fillColor: '#000080',     // navy blue
                  },
                  {
                    text: ""
                    //fillColor: '#000080',     // navy blue
                  },
                  {
                    text: ""
                    //fillColor: '#000080',     // navy blue
                  },
                  {
                    text: ""
                    //fillColor: '#000080',     // navy blue
                  },
                  {
                    text: ""
                    //fillColor: '#000080',     // navy blue
                  }
                ],
                //header 2
                [
                  {
                    text: "Principle",
                    //fillColor: '#555555',     // gray
                    color: "#000000", // white
                    alignment: "center"
                  },
                  {
                    text: "0",
                    //fillColor: '#000080',     // navy blue
                    color: "#000000", // white
                    alignment: "center"
                  },
                  {
                    text: "1",
                    //fillColor: '#000080',     // navy blue
                    color: "#000000", // white
                    alignment: "center"
                  },
                  {
                    text: "2",
                    //fillColor: '#000080',     // navy blue
                    color: "#000000", // white
                    alignment: "center"
                  },
                  {
                    text: "3",
                    //fillColor: '#000080',     // navy blue
                    color: "#000000", // white
                    alignment: "center"
                  },
                  {
                    text: "4",
                    //fillColor: '#000000',     // navy blue
                    color: "#000000", // white
                    alignment: "center"
                  },
                  {
                    text: "5",
                    //fillColor: '#000080',     // navy blue
                    color: "#000000", // white
                    alignment: "center"
                  }
                ],
                ...assessmentDetailsForDownloadArray.map((intervObj) => [
                  {
                    text: intervObj.principleName,
                    alignment: "center",
                    fontSize: 9,
                    //fillColor: intervObj.principleName === 'Guidelines'? '#C0C0C0' :
                    //           intervObj.practiceStatus0 !== 32 ? '#808080' : StatusColorCodes[intervObj.practiceStatus0],
                    //color: intervObj.principleName === 'Guidelines'? '#000000' : '#FFFFFF',
                    color: "#000000",
                    bold: true
                  },
                  {
                    text: intervObj.practice0,
                    alignment: "center",
                    fontSize: 8,
                    //fillColor: intervObj.practiceStatus0 !== 32 ? '#FFFFFF' : StatusColorCodes[intervObj.practiceStatus0],
                    //color: intervObj.practiceStatus0 !== 32 ?  '#000000' : '#FFFFFF',
                    color: "#000000"
                  },
                  {
                    text: intervObj.practice1,
                    alignment: "center",
                    fontSize: 8,
                    //fillColor: StatusColorCodes[intervObj.practiceStatus1],
                    //color: intervObj.practiceStatus1 !== 32 ?  '#000000' : '#FFFFFF',
                    color: "#000000"
                  },
                  {
                    text: intervObj.practice2,
                    alignment: "center",
                    fontSize: 8,
                    //fillColor: StatusColorCodes[intervObj.practiceStatus2],
                    //color: intervObj.practiceStatus2 !== 32 ?  '#000000' : '#FFFFFF',
                    color: "#000000"
                  },
                  {
                    text: intervObj.practice3,
                    alignment: "center",
                    fontSize: 8,
                    //fillColor: StatusColorCodes[intervObj.practiceStatus3],
                    //color: intervObj.practiceStatus3 !== 32 ?  '#000000' : '#FFFFFF',
                    color: "#000000"
                  },
                  {
                    text: intervObj.practice4,
                    alignment: "center",
                    fontSize: 8,
                    //fillColor: StatusColorCodes[intervObj.practiceStatus4],
                    //color: intervObj.practiceStatus4 !== 32 ?  '#000000' : '#FFFFFF',
                    color: "#000000"
                  },
                  {
                    text: intervObj.practice5,
                    alignment: "center",
                    fontSize: 8,
                    //fillColor: StatusColorCodes[intervObj.practiceStatus5],
                    //color: intervObj.practiceStatus5 !== 32 ?  '#000000' : '#FFFFFF',
                    color: "#000000"
                  }
                ])
              ]
            }
          }
        ];

        let docDefinition = {
          content: pdfData,
          pageOrientation: "landscape",
          pageSize: "A4",
          pageMargins: [35, 35, 35, 35]
        };

        pdfMake.createPdf(docDefinition).download(pdfName + ".pdf");
        this.$bvModal.hide("bv-export-modal");
      });
    },
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          let dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };

        img.src = url;
      });
    }
  }
};
</script>
<style scoped>
.mat-info-con {
  padding: 2rem;
  display: flex;
  align-items: center;
}

.plant-dd {
  width: 20%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  width: 160px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
  border-radius: 7px;
}

.plant-card2 {
  background: #fffce3;
  width: 185px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
  border-radius: 7px;
}

.level-con {
  padding-left: 15px;
  padding-right: 15px;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.modify-date {
  position: absolute;
  top: 167px;
  right: 50px;
}

.dropdown-custome .dropdown-menu {
  min-width: 14rem;
}

.mat-stats-con {
  display: flex;
  padding-left: 2rem;
  padding-top: 1em;
  padding-bottom: 1em;
  align-items: center;
}

.mat-status-con {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.last-ast-date {
  padding-top: 0.5rem;
  color: #5a5a5a;
}

.ast-action {
  display: flex;
  justify-content: center;
}

.take-ast-btn {
  margin-right: 1rem;
}

.text-center {
  text-align: center;
}

.pl-code {
  margin-left: 0.7rem;
}

.certify-btn {
  margin-left: 0.5rem;
}

.cml-con {
  display: flex;
  align-items: center;
}

.certify-btn-plt {
  color: #fff;
}

.certify-btn-plt:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.certify-btn:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.asmt-bt:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.cml-main-con {
  width: 215px;
  text-align: r;
  display: flex;
  justify-content: center;
}

.crt-btn-con {
  margin-left: 1rem;
}

.crt-btn-con .btn-outline-primary.disabled,
.crt-btn-con .btn-outline-primary:disabled {
  background-color: #274ba7 !important;
}

@media only screen and (max-width: 575px) {
  .mat-stats-con {
    display: block !important;
  }

  .mat-status-con {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }

  .modify-date {
    top: 437px !important;
  }
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
  margin-right: 20px;
}

#checkActionLink:hover {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.action-btn-disabled {
  border: 1px #bebebe solid;
  height: 32px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: not-allowed;
  margin-right: 20px;
}

/deep/ .export-modal .modal-body {
  padding: 2rem;
}
</style>
